import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Admin from "./Components/Admin/Admin";
import Login from "./Components/Login/Login";
import Homemanagment from "./Components/Homemanagment/Homemanagment";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Nftsdetail from "./Components/Nftsdetail/Nftsdetail";
import "./App.scss"
function ProtectedRoute({ children }) {
  const isAuthenticated = localStorage.getItem('adminAccessToken');
  return isAuthenticated ? children : <Navigate to="/" />;
}

function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    checkIfMobile();

    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  return (
    <>
      <ToastContainer autoClose={5000} style={{ fontSize: 12, fontWeight: 300 }} theme="light" position="top-center" />
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend} options={{ enableTouchEvents: isMobile }}>
        <Router>
          <Routes>
            {/* Protected Admin Route */}
            <Route path="/admin/*" element={<ProtectedRoute><Admin /></ProtectedRoute>} />

            {/* Protected Homemanagement Route */}
            <Route path="/homemanagment" element={<ProtectedRoute><Homemanagment /></ProtectedRoute>} />

            {/* Login Route */}
            <Route path="/" element={<Login />} />
            <Route path="/nftsdetail" element={<Nftsdetail />} />
          </Routes>
        </Router>
      </DndProvider>
    </>
  );
}

export default App;