import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
// import { HTML5Backend } from 'react-dnd-html5-backend';

const DraggableItem = ({
    item,
    index,
    moveItem,
    handleAddNft,
    sourceList,
    setSourceList,
    setDraggedItems,
    setNftListName,
    nftListName,
    collection,
    handleRemove,
    setRemoveBtn,
    removeBtn
    // setIsFeatured
}) => {

    console.log(item?.totalVolume, "item?.totalVolumeitem?.totalVolumeitem?.totalVolume");

    const [draggedItemData, setDraggedItemData] = useState(null);
    console.log(nftListName, "nftListNamenftListNamenftListNamenftListName");

    const ItemType = 'ITEM';

    const [, drag] = useDrag({
        type: ItemType,
        item: { id: item._id, index }, // Pass the item's id and index to the drag event
        end: (draggedItem, monitor) => {
            if (monitor.didDrop()) {

                console.log(draggedItem, "draggedItem Updated Items 1111");

                // Update the dragged items array with all items
                setDraggedItems((prevItems) => {
                    // Create a new array that reflects the current state of sourceList
                    const updatedItems = sourceList.map((srcItem, idx) => ({
                        ...srcItem,
                        trendingOrder: idx + 1, // Assuming trendingOrder is index + 1
                    }));

                    // Log the updated list for verification
                    console.log(updatedItems, "Updated Items");
                    setNftListName(nftListName);
                    // Return the updated list
                    return updatedItems;
                });

                // Optionally, send the entire updated list to an API
                // sendNftOrderApi(updatedItems);
            }
        },
    });


    const [, drop] = useDrop({
        accept: ItemType,
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveItem(draggedItem.index, index, sourceList, setSourceList);
                draggedItem.index = index;
                setDraggedItemData(draggedItem); // Set the dragged item data for API call
            }
        },
    });

    const convertedURL = item?.imageUrl?.replace("ipfs://", "https://ipfs.io/ipfs/");


    const removeHandle = () => {
        console.log(nftListName, "nftListNamenftListName ioinnn");
        // setIsFeatured(true);
        setRemoveBtn(true);
        setNftListName(nftListName);
        handleRemove(item?._id, nftListName, !removeBtn);
    }

    const countNumbersHandle = (number) => {
        if (number >= 1e12) return Math.floor(number / 1e12) + 'T';
        if (number >= 1e9) return Math.floor(number / 1e9) + 'B';
        if (number >= 1e6) return Math.floor(number / 1e6) + 'M';
        if (number >= 1e3) return Math.floor(number / 1e3) + 'K';
        return number?.toString();
    };


    return (
        <div ref={(node) => drag(drop(node))} className="home__inner">
            <div className="left_first">
                <div className="left_second">
                    <img src="\assets\sixdots.svg" className="sixdots" />
                    {/* {item?.trendingOrder} */}
                    <img src={collection ? item?.logoUrl : item.s3ImageUrl || convertedURL} className="ringimg" />
                    {/* {index} */}
                </div>
                <div>
                    <h2>{item?.name}</h2>
                    <p>{item?.collection?.name}</p>
                </div>
            </div>
            <div className="left_sidee">
                {
                    item?.totalVolume ?
                        (
                            item?.totalVolume > "0" ?
                                <h3>
                                    {countNumbersHandle(item.totalVolume?.toFixed(6))}
                                    {/* {item.totalVolume} */}
                                </h3>
                                :
                                <h3>0</h3>
                        )
                        :
                        <>
                            {
                                item.price > 0 ?
                                    <h3>{countNumbersHandle(item?.price)} USDT</h3>
                                    :
                                    <h3>0</h3>
                            }
                        </>
                }
                <button onClick={() => removeHandle()}>Remove</button>
            </div>
        </div>
    );
};

export default DraggableItem;