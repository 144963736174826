
import "./navbar.scss";
import { Link, useNavigate } from 'react-router-dom';
import Offcanvas from 'react-bootstrap/Offcanvas';

import React, { useEffect, useState } from 'react'

const Navbar = () => {


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const navigate = useNavigate();

    const handleLogout = () => {
        // Clear the authentication token from localStorage
        localStorage.removeItem('adminAccessToken');

        // Redirect the user to the login page
        navigate('/');
    };

    return (
        <>
            <section className='Head_nav'>
                <div className='main_nav'>
                    <img src='\assets\logonav.svg' />
                    <div className='inner_nav'>
                        <Link to="/admin">
                            <h1>NFt Management</h1>
                        </Link>

                        <Link to="/homemanagment">
                            <h1>Homepage Managment</h1>
                        </Link>

                    </div>
                    <button onClick={() => handleLogout()}>
                        Log Out<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M9.375 16.875C9.375 17.0408 9.30915 17.1997 9.19194 17.3169C9.07473 17.4342 8.91576 17.5 8.75 17.5H3.75C3.58424 17.5 3.42527 17.4342 3.30806 17.3169C3.19085 17.1997 3.125 17.0408 3.125 16.875V3.125C3.125 2.95924 3.19085 2.80027 3.30806 2.68306C3.42527 2.56585 3.58424 2.5 3.75 2.5H8.75C8.91576 2.5 9.07473 2.56585 9.19194 2.68306C9.30915 2.80027 9.375 2.95924 9.375 3.125C9.375 3.29076 9.30915 3.44973 9.19194 3.56694C9.07473 3.68415 8.91576 3.75 8.75 3.75H4.375V16.25H8.75C8.91576 16.25 9.07473 16.3158 9.19194 16.4331C9.30915 16.5503 9.375 16.7092 9.375 16.875ZM17.9422 9.55781L14.8172 6.43281C14.6999 6.31554 14.5409 6.24965 14.375 6.24965C14.2091 6.24965 14.0501 6.31554 13.9328 6.43281C13.8155 6.55009 13.7497 6.70915 13.7497 6.875C13.7497 7.04085 13.8155 7.19991 13.9328 7.31719L15.9914 9.375H8.75C8.58424 9.375 8.42527 9.44085 8.30806 9.55806C8.19085 9.67527 8.125 9.83424 8.125 10C8.125 10.1658 8.19085 10.3247 8.30806 10.4419C8.42527 10.5592 8.58424 10.625 8.75 10.625H15.9914L13.9328 12.6828C13.8155 12.8001 13.7497 12.9591 13.7497 13.125C13.7497 13.2909 13.8155 13.4499 13.9328 13.5672C14.0501 13.6845 14.2091 13.7503 14.375 13.7503C14.5409 13.7503 14.6999 13.6845 14.8172 13.5672L17.9422 10.4422C18.0003 10.3841 18.0464 10.3152 18.0779 10.2393C18.1093 10.1635 18.1255 10.0821 18.1255 10C18.1255 9.91787 18.1093 9.83654 18.0779 9.76066C18.0464 9.68479 18.0003 9.61586 17.9422 9.55781Z" fill="#FE0000" />
                        </svg>
                    </button>
                </div>
                <div className=' phonenav d-none'>
                    <div className="iiner_divv">
                        <img src='\assets\logonav.svg' />
                        <img onClick={handleShow} src="\assets\phonetoggle.svg" />
                    </div>

                </div>
            </section>


            <Offcanvas show={show} onHide={handleClose} placement='top' className="mainmenu">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                        <img src='\assets\logonav.svg' />
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="connectmain">
                        <Link to="/admin">
                            <h5>NFt Management</h5>
                        </Link>
                        <Link to="/homemanagment">
                        <h5>Homepage Managment</h5>

                        </Link>
                       
                        <div className="button___div">
                            <button className='connectbtn' onClick={handleClose}>
                                <p className='red'>
                                    Log Out<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                        <path d="M9.375 16.875C9.375 17.0408 9.30915 17.1997 9.19194 17.3169C9.07473 17.4342 8.91576 17.5 8.75 17.5H3.75C3.58424 17.5 3.42527 17.4342 3.30806 17.3169C3.19085 17.1997 3.125 17.0408 3.125 16.875V3.125C3.125 2.95924 3.19085 2.80027 3.30806 2.68306C3.42527 2.56585 3.58424 2.5 3.75 2.5H8.75C8.91576 2.5 9.07473 2.56585 9.19194 2.68306C9.30915 2.80027 9.375 2.95924 9.375 3.125C9.375 3.29076 9.30915 3.44973 9.19194 3.56694C9.07473 3.68415 8.91576 3.75 8.75 3.75H4.375V16.25H8.75C8.91576 16.25 9.07473 16.3158 9.19194 16.4331C9.30915 16.5503 9.375 16.7092 9.375 16.875ZM17.9422 9.55781L14.8172 6.43281C14.6999 6.31554 14.5409 6.24965 14.375 6.24965C14.2091 6.24965 14.0501 6.31554 13.9328 6.43281C13.8155 6.55009 13.7497 6.70915 13.7497 6.875C13.7497 7.04085 13.8155 7.19991 13.9328 7.31719L15.9914 9.375H8.75C8.58424 9.375 8.42527 9.44085 8.30806 9.55806C8.19085 9.67527 8.125 9.83424 8.125 10C8.125 10.1658 8.19085 10.3247 8.30806 10.4419C8.42527 10.5592 8.58424 10.625 8.75 10.625H15.9914L13.9328 12.6828C13.8155 12.8001 13.7497 12.9591 13.7497 13.125C13.7497 13.2909 13.8155 13.4499 13.9328 13.5672C14.0501 13.6845 14.2091 13.7503 14.375 13.7503C14.5409 13.7503 14.6999 13.6845 14.8172 13.5672L17.9422 10.4422C18.0003 10.3841 18.0464 10.3152 18.0779 10.2393C18.1093 10.1635 18.1255 10.0821 18.1255 10C18.1255 9.91787 18.1093 9.83654 18.0779 9.76066C18.0464 9.68479 18.0003 9.61586 17.9422 9.55781Z" fill="#FE0000" />
                                    </svg>
                                </p>
                            </button>
                        </div>



                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    )
}

export default Navbar