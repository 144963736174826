import React, { useEffect, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import "./nftsdetail.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import Environment from '../../utils/Enviroment';
import axios from 'axios';
import Loader from '../../utils/loader';

const Nftsdetail = () => {

    // ======================================= Slider =======================================
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    // ======================================= Slider =======================================

    const [loader, setLoader] = useState(false);
    const [featuredNfts, setFeaturedNfts] = useState([]);
    const [trendingNfts, setTrendingNfts] = useState([]);
    const [allCollectionList, setAllCollectionList] = useState([]);
    const [filteredCollectionList, setFilteredAllCollectionList] = useState([]);


    const getAllNftsHandle = async (filterKey, filterValue) => {
        try {
            const queryParams = new URLSearchParams({
                // page: 1,
                limit: 6,
                search: '',
                [filterKey]: filterValue, // Dynamically add filter key and value
            });

            const response = await axios.get(`${Environment?.API_URL}admins/nfts?${queryParams}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('adminAccessToken')}`,
                    'Content-Type': 'application/json',
                },
            });

            console.log(`Response for ${filterKey}=${filterValue}:`, response.data.data);
            return response?.data?.data; // Return the fetched data
        } catch (err) {
            console.error(`Error fetching data for ${filterKey}=${filterValue}:`, err);
            return [];
        }
    };

    const getAllCollectionsHandle = async (page = 1, search = '', isFeatured = true) => {
        try {
            let queryParams;
            queryParams = new URLSearchParams({
                page,
                search,
                isFeatured: true,
            });

            const response = await axios.get(`${Environment?.API_URL}admins/collection?${queryParams}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('adminAccessToken')}`,
                    'Content-Type': 'application/json',
                },
            });

            console.log("Fetched collections: ", response?.data?.data || []);

            const collections = response?.data?.data || [];
            console.log();

            // Save all collections
            setAllCollectionList(collections);

            // Filter the featured items
            const featuredItems = collections.filter(item => item.isFeatured);
            console.log(featuredItems, "vfeaturedItemsfeaturedItems");

            // Save only the featured items in the filtered list
            setFilteredAllCollectionList(featuredItems);

            return collections;  // Return the full collection list
        } catch (err) {
            console.error(`Error fetching collections:`, err);
            return null;
        }
    };


    const fetchAllFilters = async () => {
        const featuredData = await getAllNftsHandle('isFeatured', true);
        setFeaturedNfts(featuredData);

        const trendingData = await getAllNftsHandle('isTrending', true);
        setTrendingNfts(trendingData);
    };

    useEffect(() => {
        setLoader(true);
        fetchAllFilters();
        setLoader(false);
    }, []);

    useEffect(() => {
        setLoader(true);
        getAllCollectionsHandle();
        setLoader(false);
    }, []);

    return (
        <>
            {loader && <Loader />}

            <Navbar />

            <section className='admin_nftss'>
                <div className='custom-container'>
                    <div className='nfts_sections'>
                        <h1>FEATURED sNFTS</h1>
                        <div className='cardsmain'>
                            {
                                featuredNfts?.map((data, index) => {
                                    const convertedURL = data?.imageUrl?.replace("ipfs://", "https://ipfs.io/ipfs/");
                                    return (
                                        <div key={index}>
                                            <p>{data?.name}</p>
                                            <div className='img_car'>
                                                <img className='inner_imgcar' src={data?.s3ImageUrl ? data?.s3ImageUrl : convertedURL} />
                                            </div>
                                            <div className='bottom_text'>
                                                <h2>Price</h2>
                                                <h2>Creator</h2>
                                            </div>
                                            <div className='bottom_text'>
                                                <h3>{data?.price} USDT</h3>
                                                <h3>{data?.creatorDetails?.name}</h3>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className='section_second'>
                            <h1>TRENDING sNFTS</h1>
                            <Slider {...settings}>
                                {
                                    trendingNfts?.map((data, index) => {
                                        const convertedURL = data?.imageUrl?.replace("ipfs://", "https://ipfs.io/ipfs/");

                                        return (
                                            <div key={index} className='cardss'>
                                                <p>{data?.name}</p>
                                                <div className='img_car'>
                                                    <img className='inner_imgcar' src={data?.s3ImageUrl ? data?.s3ImageUrl : convertedURL} />
                                                </div>
                                                <div className='bottom_text'>
                                                    <h2>Price</h2>
                                                    <h2>Creator</h2>
                                                </div>
                                                <div className='bottom_text'>
                                                    <h3>{data?.price} USDT</h3>
                                                    <h3>{data?.creatorDetails?.name}</h3>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                        <div className='section_second'>
                            <h1>sNFT COLLECTIONS</h1>
                            <Slider {...settings}>
                                {
                                    filteredCollectionList?.map((data, index) => {
                                        return (
                                            <div key={index} className='cardss'>
                                                <p>{data?.name}</p>
                                                <div className='img_car_another'>
                                                    <div className='iner_img_divs'>
                                                        <div className='inner_top_img'>
                                                            <img className='inner_imgcar' src={data?.logoUrl} />
                                                        </div>
                                                        {
                                                            data?.nfts?.length > 0 ?
                                                                <div className='second_inner'>
                                                                    {
                                                                        data?.nfts?.map((item, index) => {
                                                                            const convertedURL = item?.imageUrl?.replace("ipfs://", "https://ipfs.io/ipfs/");
                                                                            return (
                                                                                <div key={index} className='small_icons'>
                                                                                    <img className='inner_imgcar1' src={item?.s3ImageUrl ? item?.s3ImageUrl : convertedURL} />
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                                : null
                                                        }
                                                    </div>
                                                </div>
                                                <div className='bottom_text'>
                                                    <h2>Item</h2>
                                                    <h2>Creator</h2>
                                                </div>
                                                <div className='bottom_text'>
                                                    <h3>{data?.nftsLength}</h3>
                                                    <h3>{data?.creatorDetails?.name}</h3>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
                <div className='bottom_div'>
                    <h1>PREVIEW CHANGES</h1>
                    <Link to="/homemanagment">
                        <button>Exit</button>
                    </Link>
                </div>
            </section>
        </>
    )
}

export default Nftsdetail