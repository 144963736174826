import React, { useCallback, useEffect, useRef, useState } from "react";
import Navbar from '../Navbar/Navbar';
import "./homemanagment.scss";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import Loader from "../../utils/loader";
import Environment from "../../utils/Enviroment";
import DraggableItem from "./DraggableItem";
// import { toast } from "react-toastify";
import ToastComp from "../../utils/ToastComp";
import { Link } from "react-router-dom";

const HomeManagement = () => {

    const [showMain1, setShowMain1] = useState(false); // Toggle between nfts_cards and new_second

    const toggleView = () => {
        setShowMain1(!showMain1); // Toggle between views
    };

    console.log(showMain1, "toggleView");

    const [calledAPI, setCalledAPI] = useState(false);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const [activeItemId, setActiveItemId] = useState(null);

    const [allNfts, setAllNfts] = useState(null);
    const [featuredNfts, setFeaturedNfts] = useState([]);
    const [trendingNfts, setTrendingNfts] = useState([]);

    const [search, setSearch] = useState(null);
    const [searchCollecton, setSearchCollecton] = useState(null);
    const [searchCollectionAndNft, setSearchCollectionAndNft] = useState(null);
    const [nftListName, setNftListName] = useState(null);
    const [collectionId, setCollectionId] = useState(null);
    console.log(collectionId, "collectionId", searchCollectionAndNft, showMain1);


    const [loader, setLoader] = useState(false);
    const [loader1, setLoader1] = useState(false);
    const [loader2, setLoader2] = useState(false);

    const [collectionListName, setCollectionListName] = useState(null);
    const [selectedNftId, setSelectedNftId] = useState(null);
    const [selectedCollectonId, setSelectedCollectionId] = useState(null);
    const [draggedItems, setDraggedItems] = useState([]); // Store the dragged items
    const [allCollectionList, setAllCollectionList] = useState([]);
    const [filteredCollectionList, setFilteredAllCollectionList] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [removeBtn, setRemoveBtn] = useState(false);
    console.log(filteredCollectionList, "filteredCollectionList");

    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    // Function to handle the button click to show the toast
    const handleButtonClick = () => {
        setShowToast(true);
        setTimeout(() => setShowToast(false), 5000);  // Automatically hide after 3 seconds
    };

    const handleSelectItem = (nftId) => {
        setSelectedNftId(nftId);
        setActiveItemId(nftId);
    };

    const handleSelectCollectionForId = (nftId) => {
        // setSelectedNftId(nftId);
        // setActiveItemId(nftId);
        setSearch(null);
        setCollectionId(nftId);
        nftsByCollectionId(nftId);
    };

    const handleSelectCollectionItem = (nftId) => {
        setSelectedCollectionId(nftId);
        setActiveItemId(nftId);
    };

    const handleAddNft = async () => {
        if (selectedNftId) {
            await addNewNftHandle(selectedNftId);
            handleClose();
            fetchAllFilters();
        } else {
            alert('Please select an NFT to add.');
        }
    };

    const handleRemoveNft = async (id, nftListName, removeBtn) => {
        console.log(nftListName, "nftListNamenftListNamenftListNamenftListName in removeeeee", removeBtn);

        if (id) {
            await addNewNftHandle(id, nftListName, removeBtn);
            handleClose();
            fetchAllFilters();
        } else {
            alert('Please select an NFT to add.');
        }
    };

    const handleAddCollection = async () => {
        if (selectedCollectonId) {
            await addNewCollectionHandle(selectedCollectonId, true);
            handleClose();
            fetchAllFilters();
        } else {
            alert('Please select an NFT to add.');
        }
    };

    const handleRemoveCollection = async (id) => {
        // if (selectedCollectonId) {
        await addNewCollectionHandle(id, false);
        handleClose();
        fetchAllFilters();
        // } else {
        //     alert('Please select an NFT to add.');
        // }
    };

    const moveItem = (fromIndex, toIndex, sourceList, setSourceList) => {
        const updatedList = [...sourceList];
        const [movedItem] = updatedList.splice(fromIndex, 1);
        updatedList.splice(toIndex, 0, movedItem);
        setSourceList(updatedList); // Update the source list only
    };

    const nftsByCollectionId = async (cold) => {

        // console.log(collectionId, cold, "collectionIdcollectionIdcollectionId");
        setLoader(true);
        try {
            const response = await axios.get(`${Environment?.API_URL}admins/nftsByCollectionId?&search=${searchCollectionAndNft ? searchCollectionAndNft : ''}&collectionId=${collectionId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('adminAccessToken')}`,
                    'Content-Type': 'application/json',
                },
            });

            console.log(response, "response from API...");
            setAllNfts(response?.data?.data);
            setLoader(false);
        } catch (err) {
            // setError("Error fetching data");
            setLoader(false);
            console.error(err, "error in collectionId");
        }
    }

    const addNewNftHandle = async (selectedNftId, nameListttt, removeBtnnnn) => {

        console.log("isFeatured nftListNamenftListName ==>", selectedNftId, nameListttt, removeBtnnnn);

        let payload;
        if (nameListttt == "isTrending" || nftListName == "isTrending") {
            payload = {
                ids: [selectedNftId],
                isTrending: removeBtnnnn ? removeBtnnnn == false ? true : false : true,
                // isFeatured: nftListName == "isFeatured" && removeBtn == false ? true : false,
            };
        }
        if (nameListttt == "isFeatured" || nftListName == "isFeatured") {
            payload = {
                ids: [selectedNftId],
                // isTrending: nftListName == "isTrending" && removeBtn == false ? true : false,
                isFeatured: removeBtnnnn ? removeBtnnnn == false ? true : false : true,
            };
        }

        setLoader(true);
        try {
            const response = await axios.post(`${Environment?.API_URL}admins/nftSetting`, payload, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('adminAccessToken')}`,
                    'Content-Type': 'application/json',
                },
            });
            setSearchCollectionAndNft(null);
            setShowMain1(false);
            console.log(response, "responcee in nftSetting");
            handleButtonClick();
            if (response?.data) {
            }
            setLoader(false);
        } catch (err) {
            console.error(err);
            setLoader(false);
        }
    };

    const addNewCollectionHandle = async (selectedNftId, isFeatured) => {
        const payload = {
            ids: [selectedNftId],
            // isTrending: nftListName == "isTrending" ? true : false,
            isFeatured: isFeatured,
            // isApproved: false
        };

        setLoader(true);
        try {
            const response = await axios.post(`${Environment?.API_URL}admins/collectionSetting`, payload, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('adminAccessToken')}`,
                    'Content-Type': 'application/json',
                },
            });
            console.log(response, "addNewCollectionHandle");
            setSearch(null);
            getAllCollectionsHandle();
            handleClose1();
            setSearchCollecton(null);
            setActiveItemId(null);

            if (response) {
                handleButtonClick();
            }
            setLoader(false);
        } catch (err) {
            console.error(err, "error in addNewCollectionHandle");
            setLoader(false);
        }
    };

    const handleSaveChanges = async () => {
        setLoader1(true); // Show loader while saving
        console.log(nftListName, "nftListNamenftListNamenftListNamenftListName");

        let payload;

        if (nftListName == "isFeatured") {
            payload = draggedItems.map((item, index) => ({
                id: item._id,
                featuredOrder: index + 1,
            }));
        } else if (nftListName == "isTrending") {
            payload = draggedItems.map((item, index) => ({
                id: item._id,
                trendingOrder: index + 1,
            }));
        } else {
            console.error("Invalid nftListName");
            setLoader1(false);
            return;
        }

        try {
            const response = await axios.post(`${Environment?.API_URL}admins/nftOrder`, payload, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('adminAccessToken')}`,
                    "Content-Type": "application/json",
                },
            });
            setLoader1(false);
            console.log("API Response:", response.data);
            // alert("Changes saved successfully!");
            getAllNftsHandle(nftListName, true);
            // toast?.success(response?.message);
            if (response?.data) {
                handleButtonClick();
            }
        } catch (error) {
            console.error("Error sending NFT order:", error);
            setLoader1(false);
            alert("Failed to save changes. Please try again.");
        }
    };

    const handleCollectionSaveChanges = async () => {
        setCollectionListName("isCollection");
        setLoader2(true); // Show loader while saving

        let payload;

        if (nftListName == "isFeatured") {
            payload = draggedItems.map((item, index) => ({
                id: item._id,
                featuredOrder: index + 1,
            }));
        }
        // else if (nftListName == "isTrending") {
        //     payload = draggedItems.map((item, index) => ({
        //         id: item._id,
        //         featuredOrder: index + 1,
        //     }));
        // }
        else {
            console.error("Invalid nftListName");
            setLoader2(false);
            return;
        }

        try {
            const response = await axios.post(`${Environment?.API_URL}admins/collectionOrder`, payload, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('adminAccessToken')}`,
                    "Content-Type": "application/json",
                },
            });
            setLoader2(false);
            console.log("API Response:", response.data);
            // alert("Changes saved successfully!");
            getAllNftsHandle(nftListName, true);
            // toast?.success(response?.message);
            if (response) {
                handleButtonClick();
                setCollectionListName(null);
            }
        } catch (error) {
            console.error("Error sending NFT order:", error);
            setLoader2(false);
            alert("Failed to save changes. Please try again.");
        }
    };

    const getAllNftsHandle = async (filterKey, filterValue) => {
        try {
            const queryParams = new URLSearchParams({
                // page: 1,
                limit: 6,
                search: '',
                [filterKey]: filterValue, // Dynamically add filter key and value
            });

            const response = await axios.get(`${Environment?.API_URL}admins/nfts?${queryParams}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('adminAccessToken')}`,
                    'Content-Type': 'application/json',
                },
            });

            console.log(`Response for ${filterKey}=${filterValue}:`, response.data.data);
            return response?.data?.data; // Return the fetched data
        } catch (err) {
            console.error(`Error fetching data for ${filterKey}=${filterValue}:`, err);
            return [];
        }
    };

    const getAllCollectionsHandle = async (page = 1) => {
        console.log(search, "searchsearchsearch in apii", searchCollectionAndNft, "searchCollecton", searchCollecton);

        try {
            let queryParams;
            if (show1 || show) {
                queryParams = new URLSearchParams({
                    page,
                    limit: 300,
                    search: showMain1
                        ? (searchCollectionAndNft || searchCollecton || "")
                        : (search || searchCollecton || ""),
                });
            } else {
                queryParams = new URLSearchParams({
                    page,
                    limit: 300,
                    search: showMain1
                        ? (searchCollectionAndNft || searchCollecton || "")
                        : (search || searchCollecton || ""),
                    isFeatured: show1 || show ? false : true,
                });
            }

            setLoader(true);
            const response = await axios.get(`${Environment?.API_URL}admins/collection?${queryParams}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('adminAccessToken')}`,
                    'Content-Type': 'application/json',
                },
            });

            console.log("Fetched collections: searchCollecton", response?.data?.data || []);

            const collections = response?.data?.data || [];
            console.log();

            // Save all collections
            setAllCollectionList(collections);

            // Filter the featured items
            const featuredItems = collections.filter(item => item.isFeatured);
            console.log(featuredItems, "vfeaturedItemsfeaturedItems");

            // Save only the featured items in the filtered list
            setFilteredAllCollectionList(featuredItems);
            setLoader(false);
            return collections;  // Return the full collection list
        } catch (err) {
            console.error(`Error fetching collections:`, err);
            setLoader(false);
            return null;
        }
    };

    const fetchAllFilters = async () => {
        const featuredData = await getAllNftsHandle('isFeatured', true);
        setFeaturedNfts(featuredData);

        const trendingData = await getAllNftsHandle('isTrending', true);
        setTrendingNfts(trendingData);
    };

    useEffect(() => {
        if (!calledAPI) {
            setLoader(true);
            fetchAllFilters();
            setLoader(false);
            setCalledAPI(true);
        }
    }, [calledAPI]);

    useEffect(() => {
        // if (!calledAPI) {
        getAllCollectionsHandle();
        // setCalledAPI(true);
        // }
    }, [show1, show, search, searchCollecton]);

    useEffect(() => {
        // if (!calledAPI) {
        if (collectionId || searchCollectionAndNft && showMain1 == true) {
            nftsByCollectionId();
        }
        // setCalledAPI(true);
        // }
    }, [collectionId, searchCollectionAndNft])

    return (
        <>
            {loader && <Loader />}
            <Navbar />
            <ToastComp showToast={showToast} setShowToast={setShowToast} />
            <section className='Home_managment'>
                <div className="top_feat">

                    <h1>FEATURED sNFTS</h1>

                    <div className="top_buttons_head">
                        <Link to="/nftsdetail">
                            <button className="preview_button">Preview</button>
                        </Link>
                        {
                            loader1 && nftListName == "isFeatured" ?
                                <button className="saving">
                                    <svg className="spinner" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'>
                                        <radialGradient id='a8' cx='.66' fx='.66' cy='.3125' fy='.3125' gradientTransform='scale(1.5)'>
                                            <stop offset='0' stop-color='#724EF7'></stop><stop offset='.3' stop-color='#724EF7' stop-opacity='.9'>
                                            </stop><stop offset='.6' stop-color='#724EF7' stop-opacity='.6'>
                                            </stop><stop offset='.8' stop-color='#724EF7' stop-opacity='.3'></stop>
                                            <stop offset='1' stop-color='#724EF7' stop-opacity='0'></stop>
                                        </radialGradient>
                                        <circle transform-origin='center' fill='none' stroke='url(#a8)' stroke-width='20' stroke-linecap='round' stroke-dasharray='200 1000' stroke-dashoffset='0' cx='100' cy='100' r='70'>
                                            <animateTransform type='rotate' attributeName='transform' calcMode='spline' dur='2' values='360;0' keyTimes='0;1' keySplines='0 0 1 1' repeatCount='indefinite'>
                                            </animateTransform></circle><circle transform-origin='center' fill='none' opacity='.2' stroke='#724EF7' stroke-width='20' stroke-linecap='round' cx='100' cy='100' r='70'>
                                        </circle>
                                    </svg>Saving</button>
                                :
                                <button className="savechanges" onClick={handleSaveChanges}>Save Changes</button>
                        }
                    </div>
                </div>

                <div className="head_cards">
                    {
                        featuredNfts?.length > 0 ?
                            featuredNfts.map((item, index) => (
                                <DraggableItem
                                    key={index}
                                    index={index}
                                    item={item}
                                    moveItem={moveItem}
                                    handleAddNft={handleAddNft}
                                    sourceList={featuredNfts}
                                    setSourceList={setFeaturedNfts}
                                    setDraggedItems={setDraggedItems}
                                    setNftListName={setNftListName}
                                    nftListName="isFeatured"
                                    handleRemove={handleRemoveNft}
                                    setRemoveBtn={setRemoveBtn}
                                // removeBtn={removeBtn}
                                // setIsFeatured={setIsFeatured}
                                />
                            ))
                            :
                            <div className="w-100 d-flex align-items-center justify-content-center mb-4">
                                <p style={{ fontSize: "18px", color: "black" }}>No data found!</p>
                            </div>
                    }
                </div>
                {
                    featuredNfts?.length >= 6 ?
                        <button className='nftbutton' disable style={{ opacity: '0.5', cursor: "not-allowed" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                <path d="M6.5 12.6111H18.5" stroke="#724EF7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12.5 18.6111V6.61108" stroke="#724EF7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            Add Another NFT
                        </button>
                        :
                        <button className='nftbutton' onClick={() => { handleShow(); setNftListName("isFeatured"); setCollectionListName("isCollection") }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                <path d="M6.5 12.6111H18.5" stroke="#724EF7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12.5 18.6111V6.61108" stroke="#724EF7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            Add Another NFT
                        </button>
                }

                <div className="top_feat">
                    <h1>TRENDING sNFTS</h1>
                    {
                        loader1 && nftListName == "isTrending" ?
                            <button className="saving">
                                <svg className="spinner" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'>
                                    <radialGradient id='a8' cx='.66' fx='.66' cy='.3125' fy='.3125' gradientTransform='scale(1.5)'>
                                        <stop offset='0' stop-color='#724EF7'></stop><stop offset='.3' stop-color='#724EF7' stop-opacity='.9'>
                                        </stop><stop offset='.6' stop-color='#724EF7' stop-opacity='.6'>
                                        </stop><stop offset='.8' stop-color='#724EF7' stop-opacity='.3'></stop>
                                        <stop offset='1' stop-color='#724EF7' stop-opacity='0'></stop>
                                    </radialGradient>
                                    <circle transform-origin='center' fill='none' stroke='url(#a8)' stroke-width='20' stroke-linecap='round' stroke-dasharray='200 1000' stroke-dashoffset='0' cx='100' cy='100' r='70'>
                                        <animateTransform type='rotate' attributeName='transform' calcMode='spline' dur='2' values='360;0' keyTimes='0;1' keySplines='0 0 1 1' repeatCount='indefinite'>
                                        </animateTransform></circle><circle transform-origin='center' fill='none' opacity='.2' stroke='#724EF7' stroke-width='20' stroke-linecap='round' cx='100' cy='100' r='70'>
                                    </circle>
                                </svg>Saving</button>
                            :
                            <button className="savechanges" onClick={() => handleSaveChanges()}>Save Changes</button>
                    }
                </div>

                <div className="head_cards">
                    {trendingNfts?.length > 0 ?
                        trendingNfts.map((item, index) => {
                            console.log(item, "in tranding.....");
                            return (
                                <DraggableItem
                                    key={index}
                                    index={index}
                                    item={item}
                                    moveItem={moveItem}
                                    handleAddNft={handleAddNft}
                                    sourceList={trendingNfts}
                                    setSourceList={setTrendingNfts}
                                    setDraggedItems={setDraggedItems}
                                    setNftListName={setNftListName}
                                    nftListName="isTrending"
                                    handleRemove={handleRemoveNft}
                                    setRemoveBtn={setRemoveBtn}
                                />
                            )
                        })
                        :
                        <div className="w-100 d-flex align-items-center justify-content-center mb-4">
                            <p style={{ fontSize: "18px", color: "black" }}>No data found!</p>
                        </div>
                    }
                </div>
                {trendingNfts?.length >= 6 ?
                    <button className='nftbutton' disable style={{ opacity: '0.5', cursor: "not-allowed" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                            <path d="M6.5 12.6111H18.5" stroke="#724EF7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12.5 18.6111V6.61108" stroke="#724EF7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        Add Another NFT
                    </button>
                    :
                    <button className='nftbutton' onClick={() => { handleShow(); setNftListName("isTrending"); setCollectionListName("isCollection") }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                            <path d="M6.5 12.6111H18.5" stroke="#724EF7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M12.5 18.6111V6.61108" stroke="#724EF7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        Add Another NFT
                    </button>
                }

                <div className="top_feat">
                    <h1>sNFT COLLECTIONS</h1>
                    {
                        loader2 && collectionListName == "isCollection" ?
                            <button className="saving">
                                <svg className="spinner" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 200 200'>
                                    <radialGradient id='a8' cx='.66' fx='.66' cy='.3125' fy='.3125' gradientTransform='scale(1.5)'>
                                        <stop offset='0' stop-color='#724EF7'></stop><stop offset='.3' stop-color='#724EF7' stop-opacity='.9'>
                                        </stop><stop offset='.6' stop-color='#724EF7' stop-opacity='.6'>
                                        </stop><stop offset='.8' stop-color='#724EF7' stop-opacity='.3'></stop>
                                        <stop offset='1' stop-color='#724EF7' stop-opacity='0'></stop>
                                    </radialGradient>
                                    <circle transform-origin='center' fill='none' stroke='url(#a8)' stroke-width='20' stroke-linecap='round' stroke-dasharray='200 1000' stroke-dashoffset='0' cx='100' cy='100' r='70'>
                                        <animateTransform type='rotate' attributeName='transform' calcMode='spline' dur='2' values='360;0' keyTimes='0;1' keySplines='0 0 1 1' repeatCount='indefinite'>
                                        </animateTransform></circle><circle transform-origin='center' fill='none' opacity='.2' stroke='#724EF7' stroke-width='20' stroke-linecap='round' cx='100' cy='100' r='70'>
                                    </circle>
                                </svg>Saving</button>
                            :
                            <button className="savechanges" onClick={() => handleCollectionSaveChanges()}>Save Changes</button>
                    }
                </div>

                <div className="head_cards">
                    {filteredCollectionList?.length > 0 ?
                        filteredCollectionList.map((item, index) => (
                            <DraggableItem
                                key={index}
                                index={index}
                                item={item}
                                moveItem={moveItem}
                                handleAddNft={handleAddCollection}
                                sourceList={filteredCollectionList}
                                setSourceList={setFilteredAllCollectionList}
                                setDraggedItems={setDraggedItems}
                                setNftListName={setNftListName}
                                nftListName={"isFeatured"}
                                handleRemove={handleRemoveCollection}
                                collection={true}
                                setRemoveBtn={setRemoveBtn}
                            />
                        ))
                        :
                        <div className="w-100 d-flex align-items-center justify-content-center mb-4">
                            <p style={{ fontSize: "18px", color: "black" }}>No data found!</p>
                        </div>
                    }
                </div>
                {
                    filteredCollectionList?.length >= 6 ?
                        <button className='nftbutton' disable style={{ opacity: '0.5', cursor: "not-allowed" }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                <path d="M6.5 12.6111H18.5" stroke="#724EF7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12.5 18.6111V6.61108" stroke="#724EF7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            Add Another cOLLECTION</button>
                        :
                        <button className='nftbutton' onClick={() => { handleShow1(); setNftListName("isFeatured"); setCollectionListName("isCollection") }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                <path d="M6.5 12.6111H18.5" stroke="#724EF7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M12.5 18.6111V6.61108" stroke="#724EF7" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            Add Another cOLLECTION</button>
                }
            </section>



            {/* Modal for ALL NFTs listing */}
            <Modal show={show} onHide={handleClose} centered className='modal1 nftsmodal'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="inner_data_table">
                            {/* Show backbutton_svg only when new_second is visible */}
                            {showMain1 && (
                                <svg
                                    className="backbutton_svg"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="26"
                                    viewBox="0 0 25 26"
                                    fill="none"
                                    onClick={toggleView} // Click to toggle view
                                >
                                    <path d="M3.35352 12.9472L10.3848 5.91595C10.5314 5.76935 10.7302 5.687 10.9375 5.687C11.1448 5.687 11.3436 5.76935 11.4902 5.91595C11.6368 6.06254 11.7192 6.26137 11.7192 6.46868C11.7192 6.676 11.6368 6.87482 11.4902 7.02142L5.79199 12.7187L21.0937 12.7187C21.3009 12.7187 21.4997 12.801 21.6462 12.9475C21.7927 13.094 21.875 13.2927 21.875 13.4999C21.875 13.7071 21.7927 13.9058 21.6462 14.0524C21.4997 14.1989 21.3009 14.2812 21.0937 14.2812L5.79199 14.2812L11.4902 19.9784C11.6368 20.125 11.7192 20.3239 11.7192 20.5312C11.7192 20.7385 11.6368 20.9373 11.4902 21.0839C11.3436 21.2305 11.1448 21.3129 10.9375 21.3129C10.7302 21.3129 10.5314 21.2305 10.3848 21.0839L3.35352 14.0527C3.28088 13.9801 3.22325 13.8939 3.18394 13.7991C3.14462 13.7043 3.12439 13.6026 3.12439 13.4999C3.12439 13.3973 3.14462 13.2956 3.18394 13.2008C3.22325 13.1059 3.28088 13.0198 3.35352 12.9472Z" fill="black" />
                                </svg>
                            )}
                            <h4 className="nfts_Div">Add NFT to {nftListName == "isFeatured" ? "Featured" : "Trending"}  NFTs</h4>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="Add_nfts">
                        <div className="input_search">
                            {
                                !showMain1 ?
                                    <input
                                        type="text"
                                        id="fname"
                                        name="fname"
                                        placeholder="Search Collection by name"
                                        value={search}
                                        onChange={(e) => setSearch(e?.target?.value)}
                                    // Add your search logic here
                                    />
                                    :
                                    <input
                                        type="text"
                                        id="fname"
                                        name="fname"
                                        placeholder="Search NFT by name"
                                        value={searchCollectionAndNft}
                                        onChange={(e) => setSearchCollectionAndNft(e?.target?.value)}
                                    // Add your search logic here
                                    />
                            }
                        </div>

                        <div className="top_of_div">
                            {/* Conditional rendering of nfts_cards or new_second */}
                            {!showMain1 ? (
                                <div className="nfts_cards">
                                    {
                                        allCollectionList?.length > 0 ?
                                            allCollectionList?.map((data, index) => {
                                                const convertedURL = data?.imageUrl?.replace("ipfs://", "https://ipfs.io/ipfs/");
                                                const isActive = data?._id === activeItemId;
                                                return (
                                                    <div key={index} className={`inner_cards ${isActive ? "active" : ""}`} onClick={() => {
                                                        handleSelectCollectionForId(data?._id);
                                                        toggleView();
                                                    }}>
                                                        <div className="left_card_side">
                                                            <div>
                                                                <img src={data?.logoUrl} className='ringimg' />
                                                            </div>
                                                            <div>
                                                                <h1>{data?.name}</h1>
                                                                <div className="innner__text">
                                                                    <img className="smallman_img" src={data?.creatorDetails?.picture} />
                                                                    <p>{data?.creatorDetails?.name}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                            <path d="M17.3172 10.6134L11.6922 4.98837C11.5749 4.8711 11.4159 4.80521 11.25 4.80521C11.0841 4.80521 10.9251 4.8711 10.8078 4.98837C10.6905 5.10565 10.6247 5.26471 10.6247 5.43056C10.6247 5.59641 10.6905 5.75547 10.8078 5.87275L15.3664 10.4306L3.125 10.4306C2.95924 10.4306 2.80027 10.4964 2.68306 10.6136C2.56585 10.7308 2.5 10.8898 2.5 11.0556C2.5 11.2213 2.56585 11.3803 2.68306 11.4975C2.80027 11.6147 2.95924 11.6806 3.125 11.6806L15.3664 11.6806L10.8078 16.2384C10.6905 16.3557 10.6247 16.5147 10.6247 16.6806C10.6247 16.8464 10.6905 17.0055 10.8078 17.1227C10.9251 17.24 11.0841 17.3059 11.25 17.3059C11.4159 17.3059 11.5749 17.24 11.6922 17.1227L17.3172 11.4978C17.3753 11.4397 17.4214 11.3708 17.4529 11.2949C17.4843 11.219 17.5005 11.1377 17.5005 11.0556C17.5005 10.9734 17.4843 10.8921 17.4529 10.8162C17.4214 10.7404 17.3753 10.6714 17.3172 10.6134Z" fill="black" />
                                                        </svg>
                                                    </div>
                                                );
                                            })
                                            :
                                            <div className="w-100 d-flex align-items-center justify-content-center mb-4">
                                                <p style={{ fontSize: "18px", color: "black" }}>No data found!</p>
                                            </div>
                                    }
                                </div>
                            ) : (
                                <div className="nfts_cards new_second">
                                    {allNfts?.length > 0 ?
                                        allNfts?.map((data, index) => {
                                            const convertedURL = data?.imageUrl?.replace("ipfs://", "https://ipfs.io/ipfs/");
                                            const isActive = data?._id === activeItemId;
                                            return (
                                                <div key={index} className={`inner_cards ${isActive ? "active" : ""}`} onClick={() => handleSelectItem(data?._id)}>
                                                    <div className="left_card_side">
                                                        <div>
                                                            <img src={data?.s3ImageUrl ? data?.s3ImageUrl : convertedURL} className='ringimg' />
                                                        </div>
                                                        <div>
                                                            <h1>{data?.name}</h1>
                                                        </div>
                                                    </div>
                                                    <h3>{data?.price} USDT</h3>
                                                </div>
                                            );
                                        })
                                        :
                                        <div className="w-100 d-flex align-items-center justify-content-center mb-4">
                                            <p style={{ fontSize: "18px", color: "black" }}>No data found!</p>
                                        </div>
                                    }
                                </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    {/* Footer buttons are hidden for nfts_cards */}
                    {!showMain1 ? null : (
                        <div className='buttton_div'>
                            <Button className='cancel' variant="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button
                                className='save'
                                variant="primary"
                                onClick={() => {
                                    handleClose();
                                    // handleShow2();
                                    handleAddNft();
                                }}
                            >
                                Add
                            </Button>
                        </div>
                    )}
                </Modal.Footer>
            </Modal>
            {/* Modal for ALL NFTs listing */}


            {/* Modal for Collection listing */}

            <Modal show={show1} onHide={handleClose1} centered className='modal1 nftsmodal'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Add Collection to sNFT COLLECTIONS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="Add_nfts">
                        <div className="input_search">
                            <input type="text" id="fname" name="fname" placeholder="Search NFT by name" value={searchCollecton} onChange={(e) => setSearchCollecton(e?.target?.value)} />
                            <svg className="svgsearch" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                                <path d="M10.625 10.625L14.875 14.875M7.08333 12.0417C4.34492 12.0417 2.125 9.82175 2.125 7.08333C2.125 4.34492 4.34492 2.125 7.08333 2.125C9.82175 2.125 12.0417 4.34492 12.0417 7.08333C12.0417 9.82175 9.82175 12.0417 7.08333 12.0417Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </div>
                        <div className="nfts_cards newone">
                            {
                                allCollectionList?.length > 0 ?
                                    allCollectionList?.map((data, index) => {
                                        const convertedURL = data?.imageUrl?.replace("ipfs://", "https://ipfs.io/ipfs/");
                                        const isActive = data?._id == activeItemId;

                                        return (
                                            <div key={index} className={`inner_cards ${isActive ? "active" : ""}`} onClick={() => handleSelectCollectionItem(data?._id)}>
                                                <div className="left_card_side">
                                                    <div>
                                                        <img src={data?.logoUrl ? data?.logoUrl : convertedURL} className='ringimg' />
                                                    </div>
                                                    <div>
                                                        <h1>{data?.name}</h1>
                                                        <p> {data?.collection?.name}</p>
                                                    </div>
                                                </div>
                                                <h3>{data?.price} USDT</h3>
                                            </div>
                                        )
                                    })
                                    :
                                    <div className="w-100 d-flex align-items-center justify-content-center mb-4">
                                        <p style={{ fontSize: "18px", color: "black" }}>No data found!</p>
                                    </div>
                            }
                        </div>

                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <div className='buttton_div'>
                        <Button className='cancel' variant="secondary" onClick={handleClose1}>
                            Cancel
                        </Button>
                        <Button className='save' variant="primary"
                            onClick={() => {
                                handleClose();
                                // handleShow2();
                                handleAddCollection();
                            }}>
                            Add
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            {/* Modal for Collection listing */}
        </>
    );
};

export default HomeManagement